import Vue from 'vue'
import Vuex from 'vuex'
import tab from './tab'
import user from './user'
Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    fileUrl: "https://api.housky.cn",
    //fileUrl: "http://119.96.118.235:8090",
    fileList: [], //资源库选择图片临时保存
    fileApkList: [], //资源库APK 临时保存
  },
  mutations: {
    setFileList(state, fileList) {
      state.fileList = fileList;
    },
    setFileApkList(state, fileApkList) {
      state.fileApkList = fileApkList;
    },
    setFileUrl(state, fileUrl) {
      state.fileUrl = fileUrl;
    },
  },
  modules: {
    tab,
    user
  }
})