import Storage from "../utils/storagehandle";
export default {
    state: {
        isCollapse: false, //左侧菜单折叠（暂停使用）
        isMenuSelected: Storage.getItem("isMenuSelected") || "", //左侧一级菜单选中值
        isSubMenuSelected: Storage.getItem("isSubMenuSelected") || "", //左侧二级菜单选中值
        isShowPlacesMenu: Storage.getItem("isShowPlacesMenu") || "table", //场所列表展示模式（卡片or表格）
        isMenuList: Storage.getItem("isMenuList") || [], //菜单列表
        isLinkUrl: "",
    },
    mutations: {
        setCollapse(state) {
            state.isCollapse = !state.isCollapse
        },
        setMenuSelected(state, isMenuSelected) {
            state.isMenuSelected = isMenuSelected;
            Storage.setItem('isMenuSelected', isMenuSelected)
        },
        setSubMenuSelected(state, isSubMenuSelected) {
            state.isSubMenuSelected = isSubMenuSelected;
            Storage.setItem('isSubMenuSelected', isSubMenuSelected)
        },
        setShowPlacesMenu(state, isShowPlacesMenu) {
            state.isShowPlacesMenu = isShowPlacesMenu;
            Storage.setItem('isShowPlacesMenu', isShowPlacesMenu)
        },
        setMenuList(state, isMenuList) {
            state.isMenuList = isMenuList;
            Storage.setItem('isMenuList', isMenuList)
        },
        setLinkUrl(state, isLinkUrl) {
            state.isLinkUrl = isLinkUrl;
            Storage.setItem('isLinkUrl', isLinkUrl)
        }
    }
}