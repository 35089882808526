import Storage from "../utils/storagehandle";
export default {
    state: {
        authorization: Storage.getItem("authorization") || '',//token授权码
        userInfo:Storage.getItem("userInfo")|| '',//个人信息
        placesInfo:Storage.getItem("placesInfo")||'',//当前场所信息
    },
    mutations: {
        setAuthorization(state,authorization) {
            state.authorization = authorization;
            Storage.setItem('authorization',authorization)
        },
        setUserInfo(state,userInfo) {
            state.userInfo = userInfo;
            Storage.setItem('userInfo',userInfo)
        },
        setPlacesInfo(state,placesInfo){
            state.placesInfo = placesInfo;
            Storage.setItem('placesInfo',placesInfo)
        }
    }
}