import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

//防止跳转相同页面控制台报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [{
    path: '/',
    redirect: '/login'
  },
  // {
  //   path: '/pay',
  //   name: '支付中间页',
  //   component: () => import('../external/Pay.vue'),
  // },
  {
    path: '/scanConfig',
    name: '扫码配置页',
    component: () => import('../external/ScanConfig.vue'),
  },
  {
    path: '/AppDownload',
    name: '客户端下载页',
    component: () => import('../external/AppDownload.vue'),
  },
  {
    path: '*',
    name: '404',
    component: () => import('../error/404.vue'),
  },
  {
    path: '/notFound',
    name: '1001',
    component: () => import('../error/1001.vue'),
  },
  {
    name: "登录",
    path: '/login',
    component: () => import('../view/Login.vue'),
  },
  {
    name: "注册",
    path: '/register',
    component: () => import('../view/Register.vue'),
  },
  {
    name: "忘记密码",
    path: '/forgetPassWord',
    component: () => import('../view/ForgetPassWord.vue'),
  },
  {
    name: "场所列表",
    path: '/placesList',
    component: () => import('../view/PlacesList.vue'),
  },
  {
    name: "支付中",
    path: "/payment",
    component: () => import('../external/Payment.vue')
  },
  {
    name: "支付完成",
    path: "/paymentSuccess",
    component: () => import('../external/PaymentSuccess.vue')
  },
  {
    name: "主页",
    path: '/home',
    component: () => import('../view/layout/Home.vue'),
    // redirect: '/systemConfig',
    children: [{
        name: "参数配置",
        path: '/systemConfig',
        component: () => import('../view/system/SystemConfig.vue'),
        redirect: '/basicSys',
        children: [{
            name: "基础配置",
            path: '/basicSys',
            component: () => import('../view/system/systemConfig/BasicInfo.vue'),
          },
          {
            name: "直播配置",
            path: '/liveSys',
            component: () => import('../view/system/systemConfig/LiveInfo.vue'),
          },
          {
            name: "点播配置",
            path: '/vodSys',
            component: () => import('../view/system/systemConfig/VodInfo.vue'),
          },
          {
            name: "短信配置",
            path: '/smsSys',
            component: () => import('../view/system/systemConfig/SmsInfo.vue'),
          },
          {
            name: "短信模板",
            path: '/smsTemplate',
            component: () => import('../view/system/systemConfig/SmsTemplate.vue'),
          },
          {
            name: "支付配置",
            path: '/paySys',
            component: () => import('../view/system/systemConfig/PayInfo.vue'),
          },
          {
            name: "微信配置",
            path: '/wxSys',
            component: () => import('../view/system/systemConfig/WxInfo.vue'),
          },
          {
            name: "腾讯云对象存储",
            path: '/txColudSys',
            component: () => import('../view/system/systemConfig/TxColudInfo.vue'),
          }
        ]
      },
      {
        name: "系统资讯",
        path: '/systemInformation',
        component: () => import('../view/system/SystemInformation.vue'),
      },
      {
        name: "系统日志",
        path: '/systemLog',
        component: () => import('../view/system/SystemLog.vue'),
      },
      {
        name: "权限配置",
        path: '/menuPower',
        component: () => import('../view/system/MenuPower.vue'),
      },
      {
        name: "操作员管理",
        path: '/operator',
        component: () => import('../view/system/Operator.vue'),
      },
      {
        name: "APK升级",
        path: '/apkUpgrade',
        component: () => import('../view/system/ApkUpgrade.vue'),
      },
      {
        name: "应用商店",
        path: '/appStore',
        component: () => import('../view/system/AppStore.vue'),
      },
      {
        name: "基本信息",
        path: '/placesInfo',
        component: () => import('../view/places/PlacesInfo.vue'),
      },
      {
        name: "通用选项",
        path: '/currency',
        component: () => import('../view/places/Currency.vue'),
      },
      {
        name: "语言管理",
        path: '/language',
        component: () => import('../view/places/Language.vue'),
      },
      {
        name: "数据面板",
        path: '/dashboard',
        component: () => import('../view/places/Dashboard.vue'),
      },
      {
        name: "角色管理",
        path: '/role',
        component: () => import('../view/places/Role.vue'),
      },
      {
        name: "员工管理",
        path: '/staff',
        component: () => import('../view/places/Staff.vue'),
      },
      {
        name: "终端列表",
        path: '/terminalEquipment',
        component: () => import('../view/places/TerminalEquipment.vue'),
      },
      {
        name: "热点管理",
        path: '/hotspotManagement',
        component: () => import('../view/places/HotspotManagement.vue'),
      },

      {
        name: "桌面主题",
        path: '/homeTheme',
        component: () => import('../view/interface/HomeTheme.vue'),
      },
      {
        name: "开机广告",
        path: '/bootSettings',
        component: () => import('../view/interface/BootSettings.vue'),
      },
      {
        name: "欢迎界面",
        path: '/bootInterface',
        component: () => import('../view/interface/BootInterface.vue'),
      },
      {
        name: "主界面",
        path: '/mainInterface',
        component: () => import('../view/interface/MainInterface.vue'),
      }, {
        name: "子界面",
        path: '/subInterface',
        component: () => import('../view/interface/SubInterface.vue'),
      },
      {
        name: "应用列表",
        path: '/applicationList',
        component: () => import('../view/interface/ApplicationList.vue'),
      },
      {
        name: "预设界面",
        path: '/preseInterface',
        component: () => import('../view/interface/PreseInterface.vue'),
      },
      {
        name: "我的账务",
        path: '/myAccounts',
        component: () => import('../view/accounting/MyAccounts.vue'),
      },
      {
        name: "套餐管理",
        path: '/package',
        component: () => import('../view/accounting/Package.vue'),
      },
      {
        name: "充值中心",
        path: '/recharge',
        component: () => import('../view/accounting/Recharge.vue'),
      },
      {
        name: "网络节目源",
        path: '/networkSource',
        component: () => import('../view/live/NetworkSource.vue'),
      },
      {
        name: "本地节目源",
        path: '/localSource',
        component: () => import('../view/live/LocalSource.vue'),
      },
      {
        name: "EPG管理",
        path: '/epgManage',
        component: () => import('../view/live/EpgManage.vue'),
      },
      {
        name: "IPTV网关",
        path: '/gateway',
        component: () => import('../view/live/Gateway.vue'),
      },

      {
        name: "滚动通知",
        path: '/scrollNotice',
        component: () => import('../view/business/notice/ScrollNotice.vue'),
      },
      {
        name: "图片通知",
        path: '/imgNotice',
        component: () => import('../view/business/notice/ImgNotice.vue'),
      },
      {
        name: "视频通知",
        path: '/videoNotice',
        component: () => import('../view/business/notice/VideoNotice.vue'),
      },
      {
        name: "紧急通知",
        path: '/urgentNotice',
        component: () => import('../view/business/notice/UrgentNotice.vue'),
      },
      {
        name: "商品分类",
        path: '/mallCategory',
        component: () => import('../view/business/mall/MallCategory.vue'),
      },
      {
        name: "商品库",
        path: '/commodity',
        component: () => import('../view/business/mall/Commodity.vue'),
      },
      {
        name: "景点库",
        path: '/scenery',
        component: () => import('../view/business/scenery/Scenery.vue'),
      },
      {
        name: "快速送物",
        path: '/loanService',
        component: () => import('../view/business/room/LoanService.vue'),
      }
    ]
  }

]

const router = new VueRouter({
  // mode: 'history',
  routes
})

//全局导航守卫
router.beforeEach((to, from, next) => {
  // console.log(to);
  // console.log(from);
  // if(to.path=='/login'){
  //   next({
  //     path:from.path
  //   })
  // }
  next();

})
export default router