<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
export default {
  name: "App"
};
</script>

<style lang="scss">
* {
  font-family: "PingFang SC", "Helvetica Neue", Helvetica, Tahoma, Arial,
    "Microsoft Yahei", "Hiragino Sans GB", "WenQuanYi Micro Hei", sans-serif;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.el-dialog {
  border-radius: 8px !important;
}
.el-dialog__header {
  padding: 10px !important;
  background: #025cdf;
  color: #ffffff;
  font-size: 16px;
  font-weight: 400;
  text-align: center;
  border-radius: 8px 8px 0 0;
  .el-dialog__headerbtn {
    top: 12px;
    .el-dialog__close {
      color: #ffffff;
    }
    .el-dialog__close:hover {
      color: #3d8cff;
    }
  }
}
.el-dialog__body {
  padding: 0px 20px !important;
}
.el-dialog__footer {
  padding: 20px !important;
  border-top: 1px solid #d7dae2;
}

.el-dropdown {
  cursor: pointer;
}
.el-card {
  border: none !important;
}
.el-card__header {
  font-weight: 700;
}
.el-button--primary:hover,
.el-button--primary:focus {
  background: #3d8cff !important;
  border-color: #3d8cff !important;
}
.el-button--primary.is-plain {
  border-color: transparent !important;
}

.el-menu-demo {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.1);
  border-radius: 4px;
}
.el-menu--horizontal > .el-menu-item {
  height: 50px;
  line-height: 50px;
}
.el-menu--horizontal .el-menu-item:not(.is-disabled):focus,
.el-menu--horizontal .el-menu-item:not(.is-disabled):hover {
  background: rgba($color: #5e72f9, $alpha: 0.1) !important;
}
.el-menu--horizontal > .el-menu-item.is-active {
  background: rgba($color: #5e72f9, $alpha: 0.1) !important;
}
</style>
