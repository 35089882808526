import Vue from 'vue'
import App from './App.vue'

// import './theme/index.css'
import ElementUI from 'element-ui'
import '../element-variables.scss'
import './theme/fonts/iconfont.css' //阿里矢量图标

import store from './store/index'
import router from './router/index'
import axios from 'axios'


// 防抖处理-立即执行 500ms内持续点击将不会执行click事件
const on = Vue.prototype.$on
Vue.prototype.$on = function (event, func) {
  let timer;
  let flag = true;
  let newFunc = func
  if (event == 'click') {
    newFunc = function () {
      if(flag) {
        func.apply(this, arguments)
        flag = false
      }
      clearTimeout(timer)
      timer = setTimeout(function () {
        flag = true
      }, 500)
    }
  }
  on.call(this, event, newFunc)
}

Vue.use(ElementUI);
axios.defaults.baseURL='http://127.0.0.1:8090'
//axios.defaults.baseURL=process.env.NODE_ENV==="production"?"https://api.housky.cn":"/api"
Vue.config.productionTip = false
new Vue({
  store,
  router,
  render: h => h(App)
}).$mount('#app')
